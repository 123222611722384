import Vue from 'vue'
import VueRouter from 'vue-router'
import CodeScanning from "@/views/CodeScanning";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'codescan',
    component: CodeScanning
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/Statistics.vue')
  },
  {
    path: '/rankings',
    name: 'rankings',
    component: () => import('../views/Rankings.vue')
  },
  {
    path: '/diploma',
    name: 'diploma',
    component: () => import('../views/Diploma.vue'),
    props: true,
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
