<template>
  <div class="codescan">
    <div class="bg"></div>
    <div class="code-scan-block">
      <svg width="276" height="330" viewBox="0 0 276 330" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_3670)">
          <path
              d="M137.94 330C150.64 330 275.88 283.38 275.88 164.76V33.42L137.94 0L0 33.42V164.76C0 283.38 125.24 330 137.94 330Z"
              fill="#00CFFF"/>
          <path
              d="M275.88 33.42L137.94 0L12.2 45.07L0 164.76C0 283.38 125.24 330 137.94 330V96.61L263.68 45.06L275.88 33.42Z"
              fill="#00FFFF"/>
          <path
              d="M137.94 315.4C149.52 315.4 263.68 272.91 263.68 164.78V45.07L137.94 14.6L12.2002 45.07V164.79C12.2002 272.91 126.36 315.41 137.94 315.41V315.4Z"
              fill="#003666"/>
          <g style="mix-blend-mode:multiply" opacity="0.35">
            <path d="M137.94 315.4C149.52 315.4 263.68 272.91 263.68 164.78V45.07L137.94 14.6V315.4Z" fill="#003666"/>
          </g>
          <path
              d="M54.9602 165C55.2302 168.89 56.3402 171.57 59.6002 171.57C62.8602 171.57 63.7802 169.44 63.7802 166.75C63.7802 163.47 62.1302 161.54 56.5502 158.68C48.3102 154.43 45.2002 150.84 45.2002 143.87C45.2002 135.76 50.1602 130.44 59.5502 130.44C70.6002 130.44 73.7602 137.34 73.8502 144.62H63.3602C63.1802 141.32 62.5702 138.48 59.4802 138.48C57.0602 138.48 55.9702 140.05 55.9702 142.7C55.9702 145.35 57.0302 146.82 62.1802 149.39C70.9702 153.81 74.7002 157.56 74.7002 165.53C74.7002 173.5 70.1202 179.61 59.4802 179.61C47.9502 179.61 44.4302 173.04 44.2002 164.99H54.9502L54.9602 165Z"
              fill="#00CFFF"/>
          <path d="M84.7802 140.23H75.4302V131.32H104.95V140.23H95.6502V178.73H84.7802V140.23Z" fill="#00CFFF"/>
          <path
              d="M132.97 158.63H118.62V169.93H134.87L133.6 178.73H107.94V131.32H133.63V140.23H118.62V149.77H132.97V158.64V158.63Z"
              fill="#00CFFF"/>
          <path
              d="M137.94 131.32H151.48C163.85 131.32 168.9 138.34 168.9 154.11C168.9 170.91 163.92 178.73 151.09 178.73H137.93V131.32H137.94ZM148.88 170.45H150.9C155.97 170.45 157.45 166.87 157.45 153.94C157.45 143.11 156.11 139.69 150.97 139.69H148.88V170.46V170.45Z"
              fill="#00CFFF"/>
          <path
              d="M197.08 158.63H182.73V169.93H198.98L197.71 178.73H172.05V131.32H197.74V140.23H182.73V149.77H197.08V158.64V158.63Z"
              fill="#00CFFF"/>
          <path
              d="M201.19 178.73V131.32H213.11C220.32 153.66 221.99 159.04 222.48 161.2H222.67C222.2 155.24 222.05 147.42 222.05 140.02V131.31H231.67V178.72H219.93C212.44 154.08 211.04 149.3 210.5 147.25H210.28C210.66 152.95 210.85 161.74 210.85 169.49V178.72H201.19V178.73Z"
              fill="#00CFFF"/>
          <path
              d="M44.21 186.98H52.1C56.62 186.98 59.34 189.64 59.34 194.18C59.34 198.07 57.47 199.79 55.77 200.4C57.97 201.19 60.27 203.22 60.27 207.73C60.27 213.07 57 216.33 51.89 216.33H44.21V186.99V186.98ZM50.96 199.11C54.56 199.11 55.82 197.54 55.82 194.5C55.82 191.63 54.31 189.94 51.33 189.94H47.76V199.11H50.97H50.96ZM47.75 213.4H51.54C54.83 213.4 56.64 211.45 56.64 207.71C56.64 203.97 54.78 202.04 50.93 202.04H47.75V213.4Z"
              fill="#00CFFF"/>
          <path
              d="M84.34 207.37L82.34 216.32H78.73L85.76 186.98H90.44L97.66 216.32H93.84L91.81 207.37H84.34ZM91.1 204.19L88.05 190.36H87.94L85.03 204.19H91.1V204.19Z"
              fill="#00CFFF"/>
          <path d="M117.93 190.18H111.44V186.98H128.02V190.18H121.53V216.32H117.92V190.18H117.93Z" fill="#00CFFF"/>
          <path d="M153.64 190.18H147.15V186.98H163.73V190.18H157.24V216.32H153.63V190.18H153.64Z" fill="#00CFFF"/>
          <path d="M183.65 186.98H187.26V213.18H197.65L197.18 216.32H183.65V186.98V186.98Z" fill="#00CFFF"/>
          <path
              d="M230.57 202.43H220.85V213.15H231.66L231.18 216.31H217.28V186.97H231.05V190.17H220.85V199.24H230.57V202.42V202.43Z"
              fill="#00CFFF"/>
          <path
              d="M117.38 44.86C113.87 48.3 108.45 50.72 101.15 51.32V63.86C103.89 64.22 108.67 64.59 111.7 64.59V115.11H44.21V123.99H130.06V44.86H117.38Z"
              fill="#00CFFF"/>
          <path
              d="M231.67 115.11H171.11V44.86H158.43C154.92 48.3 149.5 50.72 142.2 51.32V63.86C144.94 64.22 149.72 64.59 152.75 64.59V124H231.67V115.12V115.11Z"
              fill="#00CFFF"/>
          <path
              d="M62.19 149.4C57.03 146.82 55.98 145.42 55.98 142.71C55.98 140 56.9 138.75 59.01 137.55C63.61 134.92 73.86 139.37 73.86 144.63C73.77 137.35 70.61 130.45 59.56 130.45C50.17 130.45 45.21 135.77 45.21 143.88C45.21 155.42 68.53 153.87 73.33 158.63C71.54 154.93 67.97 152.31 62.2 149.4H62.19Z"
              fill="#00FFFF"/>
          <path
              d="M59.6 175.11C48.9 175.11 44.49 168.9 44.21 165C44.44 173.05 47.96 179.62 59.49 179.62C69.21 179.62 73.86 174.41 74.59 167.54C73.87 170.41 69.35 175.11 59.6 175.11V175.11Z"
              fill="#00FFFF"/>
          <path d="M84.7802 140.23L75.4302 131.32H104.95V140.23H95.6502L84.7802 178.73V140.23Z" fill="#00FFFF"/>
          <path d="M133.63 140.23L107.94 131.32V140.23H133.63Z" fill="#00FFFF"/>
          <path d="M132.97 149.77L107.94 158.63H132.97V149.77Z" fill="#00FFFF"/>
          <path d="M107.94 169.93V178.73H133.6L107.94 169.93Z" fill="#00FFFF"/>
          <path d="M197.74 131.32H172.05L197.74 140.23V131.32Z" fill="#00FFFF"/>
          <path d="M197.08 149.77H172.05V158.63L197.08 149.77Z" fill="#00FFFF"/>
          <path d="M172.05 169.93L197.71 178.73L198.98 169.93H172.05Z" fill="#00FFFF"/>
          <path
              d="M137.94 131.32H151.48C163.85 131.32 168.9 138.34 168.9 154.11C168.9 170.91 161.7 170.45 148.88 170.45L137.94 131.32V131.32ZM148.88 170.45H150.9C155.97 170.45 157.45 166.87 157.45 153.94C157.45 143.11 156.11 139.69 150.97 139.69H148.88V170.46V170.45Z"
              fill="#00FFFF"/>
          <path
              d="M201.19 178.73V131.32C201.19 131.32 221.99 159.04 222.48 161.2L231.67 178.73L222.05 131.32H231.67V178.73H219.93C212.44 154.09 211.04 149.31 210.5 147.26H210.28L201.19 178.73V178.73Z"
              fill="#00FFFF"/>
          <path
              d="M47.76 189.94L52.1 186.98C56.62 186.98 59.34 189.64 59.34 194.18C59.34 198.07 57.08 199.12 47.76 199.12C49.96 199.91 60.28 197.92 60.28 207.73C60.28 213.07 52.88 213.41 47.76 213.41L44.21 216.33L47.76 189.95V189.94ZM50.97 199.11C54.57 199.11 55.83 197.54 55.83 194.5C55.83 191.63 54.32 189.94 51.34 189.94H47.77V199.11H50.98H50.97ZM47.76 213.4H51.55C54.84 213.4 56.65 211.45 56.65 207.71C56.65 203.97 54.79 202.04 50.94 202.04H47.76V213.4Z"
              fill="#00FFFF"/>
          <path
              d="M88.0401 190.36H87.9301L85.0201 204.19L82.3301 216.32L85.7501 186.98H90.4301L97.6501 216.32L88.0401 190.37V190.36Z"
              fill="#00FFFF"/>
          <path d="M117.93 190.18L111.44 186.98H128.02V190.18H121.53V216.32L117.92 190.18H117.93Z" fill="#00FFFF"/>
          <path d="M157.25 190.18H147.15V186.98L163.73 190.18H157.24V216.32H153.63L157.24 190.18H157.25Z"
                fill="#00FFFF"/>
          <path d="M183.65 186.98L187.26 213.18H197.65L183.65 216.32V186.98V186.98Z" fill="#00FFFF"/>
          <path d="M231.05 186.98L217.29 190.18H231.05V186.98Z" fill="#00FFFF"/>
          <path d="M217.29 213.15L231.19 216.32L231.67 213.15H217.29Z" fill="#00FFFF"/>
          <path d="M230.57 199.25H217.29L230.57 202.43V199.25Z" fill="#00FFFF"/>
          <path d="M44.21 223.17V232.06H121.18V270.36H130.06V223.17H44.21Z" fill="#00CFFF"/>
          <path d="M231.67 232.06V223.17H145.82V270.36H154.7V232.06H231.67Z" fill="#00CFFF"/>
          <path d="M44.21 223.17L121.18 232.06V270.36L130.06 223.17H44.21Z" fill="#00FFFF"/>
          <path d="M231.67 232.06V223.17L154.7 232.06L145.82 270.36H154.7V232.06H231.67Z" fill="#00FFFF"/>
          <path
              d="M117.38 44.86C113.87 48.3 108.45 50.72 101.15 51.32V63.86C103.89 64.22 108.67 64.59 111.7 64.59V115.11H44.21V123.99L111.7 115.11L130.06 44.86H117.38V44.86Z"
              fill="#00FFFF"/>
          <path
              d="M231.67 115.11H171.11V44.86H158.43C154.92 48.3 149.5 50.72 142.2 51.32V63.86L171.11 44.87L152.75 124.01L231.67 115.13V115.11Z"
              fill="#00FFFF"/>
        </g>
        <defs>
          <clipPath id="clip0_1_3670">
            <rect width="275.88" height="330" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <h2>Vul hier jouw unieke schaatskaart code in.</h2>
      <fieldset name='number-code' id="code">
        <input type="number" min='0' max='9' name='number-code-0' data-number-code-input='0' required/>
        <input type="number" min='0' max='9' name='number-code-1' data-number-code-input='1' required/>
        <input type="number" min='0' max='9' name='number-code-2' data-number-code-input='2' required/>
        <input type="number" min='0' max='9' name='number-code-3' data-number-code-input='3' required/>
        <input type="number" min='0' max='9' name='number-code-4' data-number-code-input='4' required/>
        <input type="number" min='0' max='9' name='number-code-5' data-number-code-input='5' required/>
      </fieldset>
      <p>Op jouw schaatskaart staat een nummer, vul dit nummer hier in om je persoonlijke statistieken te zien.</p>
      <router-link style="width: 100%" to="/statistics">
      <button class="send-code">Bevestigen</button>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.codescan {
  height: 100%;

  .bg {
    background-image: url('@/assets/11steden_bkg.webp');
    z-index: -1;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    background-color: #1d1564;
  }

  .code-scan-block {
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    width: 500px;
    top: 12vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
    padding: 48px 60px;
    box-shadow: 0 10.4182px 13.8909px -3.47273px rgba(16, 24, 40, 0.08), 0px 3.47273px 5.20909px -1.73636px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    @media (max-width: 600px) {
      width: 90%;
      padding: 48px 32px;
    }

    @media (max-width: 430px) {
      width: 93%;
      padding: 48px 20px;
    }
    @media (max-width: 360px) {
      width: 95%;
      padding: 48px 18px;
    }

    .send-code {
      color: white;
      background-color: #003666;
      border-radius: 12px;
      width: 100%;
      border: none;
      height: 50px;
      font-size: 17px;
      margin-top: 15px;
      cursor: pointer;
      font-weight: 600;
    }

    h2 {
      font-size: 22px;
      line-height: 26px;
    }

    svg {
      width: 160px;
      height: fit-content;
    }

    fieldset {
      border: none;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      @media (max-width: 400px) {
        gap: 5px;
      }

      input {
        background: #f1f1f1;
        border-radius: 14px;
        width: 40px;
        height: 60px;
        border: none;
        font-weight: 600;
        font-size: 25px;
        text-align: center;
        @media (max-width: 360px) {
          width: 35px;
          height: 55px;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    }

  }
}
</style>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'CodeScanning',
  components: {},
  mounted: () => {
    // Elements
    const numberCodeForm = document.getElementById('code');
    const numberCodeInputs = document.querySelectorAll('#code input');

// Event callbacks
    const handleInput = ({target}) => {
      if (!target.value.length) {
        return target.value = null;
      }

      const inputLength = target.value.length;
      let currentIndex = Number(target.dataset.numberCodeInput);

      if (inputLength > 1) {
        const inputValues = target.value.split('');

        inputValues.forEach((value, valueIndex) => {
          const nextValueIndex = currentIndex + valueIndex;

          if (nextValueIndex >= numberCodeInputs.length) {
            return;
          }

          numberCodeInputs[nextValueIndex].value = value;
        });

        currentIndex += inputValues.length - 2;
      }

      const nextIndex = currentIndex + 1;

      if (nextIndex < numberCodeInputs.length) {
        numberCodeInputs[nextIndex].focus();
      }
    }

    const handleKeyDown = e => {
      const {code, target} = e;

      const currentIndex = Number(target.dataset.numberCodeInput);
      const previousIndex = currentIndex - 1;
      const nextIndex = currentIndex + 1;

      const hasPreviousIndex = previousIndex >= 0;
      const hasNextIndex = nextIndex <= numberCodeInputs.length - 1

      switch (code) {
        case 'ArrowLeft':
        case 'ArrowUp':
          if (hasPreviousIndex) {
            numberCodeInputs[previousIndex].focus();
          }
          e.preventDefault();
          break;

        case 'ArrowRight':
        case 'ArrowDown':
          if (hasNextIndex) {
            numberCodeInputs[nextIndex].focus();
          }
          e.preventDefault();
          break;
        case 'Backspace':
          if (!e.target.value.length && hasPreviousIndex) {
            numberCodeInputs[previousIndex].value = null;
            numberCodeInputs[previousIndex].focus();
          }
          break;
        default:
          break;
      }
    }

// Event listeners
    numberCodeForm.addEventListener('input', handleInput);
    numberCodeForm.addEventListener('keydown', handleKeyDown);
  },
  created() {
  }
}
</script>
